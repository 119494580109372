import { ReactQueryKey } from '@enums';
import { File } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

const fetchDoc = async ({ fileId }: { fileId: number }) => {
  const { file } = await postGraphql<{ file: File }>(
    gql`
      query DOC_DETAILS($fileId: Int!) {
        file(id: $fileId) {
          id
          name
          metaData
          downloadUrl
          type
          annotations
          projectId
          privilege
          isArchived
          sharedWithPortal
          stage {
            id
            name
          }
          form {
            id
            file {
              projectId
            }
            formLayouts(filter: { type: { equalTo: "COLUMN" } }) {
              column {
                id
                projectPropertyValuesByColumnId {
                  textValue
                }
                projectColumnId
                mappedName
                type
              }
            }
          }
          createdByUser {
            id
            firstName
            lastName
            avatarUrl
          }
          createdAt
          updatedAt
        }
      }
    `,
    { fileId }
  );

  return file;
};

interface Props {
  fileId?: number | null;
  refetchOnMount?: boolean;
}

export const useDocDetails = ({ fileId, refetchOnMount }: Props) => {
  const {
    data: doc,
    isLoading,
    isFetching
  } = useQuery<File>([ReactQueryKey.Files, ReactQueryKey.FileDetails, fileId], () => fetchDoc({ fileId }), {
    enabled: Boolean(fileId),
    refetchOnMount,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 3600 * 24
  });

  return {
    doc,
    isLoading,
    isFetching
  };
};
