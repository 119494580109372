import { getLighterBgColor } from '@utils/colors';
import styled from 'styled-components';
import { BadgeProps, BadgeSize } from './types';

const WHITE_COLORS = ['#fff', '#ffffff', 'white'];

const isWhite = (color?: string) => (color ? WHITE_COLORS.includes(color.toLowerCase()) : false);

export const Container = styled.div<BadgeProps>`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  gap: 4px;
  max-width: 100%;
  color: ${(props) => props.color};
  background-color: ${({ color, bgColor }) => bgColor ?? getLighterBgColor(color)};
  white-space: nowrap;
  border: ${({ bgColor }) => (isWhite(bgColor) ? '1px solid #DFDFE8' : 'none')};

  svg {
    flex-shrink: 0;
  }

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  ${({ size = BadgeSize.Default }) => {
    switch (size) {
      case BadgeSize.Large:
        return `
            height: 24px;
            padding: 0 6px;
            font-size: 12px;
            font-weight: 500;
            border-radius: 6px;
            letter-spacing: 0.6px;
            `;
      default:
        return `
            height: 20px;
            padding: 0 6px;
            font-size: 10px;
            font-weight: 500;
            border-radius: 4px;
            letter-spacing: 0.5px;
            `;
    }
  }}
`;
