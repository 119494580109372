import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { CommentType, ProjectStatus } from '@generated/types/graphql';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Textarea } from '@kit/ui/Textarea';
import React, { useCallback, useState } from 'react';
import { PROJECT_STATUS_OPTIONS } from '@features/Analytics/constants';
import { useComments } from '@hooks/useComments';
import { Description, Label } from './styled';

interface Props {
  project: {
    id: number;
    uid: number;
    title: string;
  };
  newStatus: ProjectStatus;
  onClose: () => void;
}

export const StatusChangeComment = ({ project, newStatus, onClose }: Props) => {
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const statusDisplayName = PROJECT_STATUS_OPTIONS.find((option) => option.id === newStatus)?.name;

  const handleCommentChange = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  }, []);

  const {
    create: { mutateAsync: createComment }
  } = useComments();

  const submitForm = useCallback(async () => {
    setIsSubmitting(true);

    await createComment({
      projectId: project.id,
      comment: comment.trim(),
      type: CommentType.Project
    });

    setIsSubmitting(false);
    onClose();
  }, [comment, createComment, onClose, project.id]);

  return (
    <>
      <ModalBody width="752px">
        <Description>
          You’ve changed the Status for{' '}
          <b>
            Project #{project.uid}: {project.title}
          </b>{' '}
          to <b>{statusDisplayName}</b>. Add an optional comment with an explanation.
        </Description>
        <div>
          <Label>Comment (optional)</Label>
          <Textarea autoFocus value={comment} onChange={handleCommentChange} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={submitForm} disabled={!comment.trim() || isSubmitting} variant={ButtonVariant.Primary}>
          Comment
        </Button>
      </ModalFooter>
    </>
  );
};
