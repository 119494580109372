import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { System } from '@generated/types/graphql';
import { SystemWithStats } from '@hooks/systems/types';
import { ChartMilestone, chartMilestoneConfig } from '@hooks/systems/constants';
import fleetChartsAPI, { Meter, TotalEnergy, TotalPower } from '@services/api/fleetChartsAPI';

type SystemOnMap = System & Pick<SystemWithStats, 'peakPower' | 'productionYesterday'>;

export const useSystemOnMap = (id: number) =>
  useQuery<SystemOnMap>({
    queryKey: [ReactQueryKey.System, 'useSystemOnMap', id],
    queryFn: async () => {
      try {
        const system = await postGraphql<{ system: SystemOnMap }>(
          gql`
            query ($id: Int!) {
              system(id: $id) {
                id
                status
                providerStatus
                name
                number
                uuid

                integration {
                  id
                  provider
                }

                project {
                  accountType
                  address
                  imageUrl
                  streetViewUrl
                  title
                }
              }
            }
          `,
          { id }
        );

        const yesterdayInterval = chartMilestoneConfig[ChartMilestone.yesterday].interval()

        const {
          data: [peakPower, productionYesterday]
        } = await fleetChartsAPI.getSystemsCharts({
          systems: [id],
          charts: [
            {
              metric: 'power',
              kind: 'total',
              meter: Meter.Production,
              period: '1 year'
            },
            {
              metric: 'energy',
              kind: 'total',
              meter: Meter.Production,
              period: '1 day',
              startAt: yesterdayInterval?.start.toISO(),
              endBefore: yesterdayInterval?.end.toISO()
            }
          ]
        });

        return {
          ...system.system,
          productionYesterday: (productionYesterday as TotalEnergy).value,
          peakPower: (peakPower as TotalPower).value
        } as SystemOnMap;
      } catch (error) {
        throw apiErrorHandler('Error fetching system', error);
      }
    },
    enabled: !!id
  });
