import { ReactQueryKey } from '@enums';
import { Project, File } from '@generated/types/graphql';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

const fetchDocs = async ({ projectId }: { projectId: number }) => {
  const {
    project: {
      projectFilesConnection: { nodes: docs },
      projectPropertiesValues
    }
  } = await postGraphql<{ project: Project }>(
    gql`
      query ($projectId: Int!) {
        project(id: $projectId) {
          projectFilesConnection(
            filter: { isArchived: { equalTo: false }, privilege: { notEqualTo: "none" } }
            orderBy: UPDATED_AT_DESC
          ) {
            nodes {
              id
              name
              metaData
              downloadUrl
              type
              projectId
              isArchived
              sharedWithPortal
              privilege
              stage {
                id
                name
              }
              form {
                id
              }
              createdByContact {
                id
                name
              }
              createdByUser {
                id
                firstName
                lastName
                avatarUrl
              }
              createdAt
              updatedAt
            }
          }

          projectPropertiesValues(filter: { fileValuesIndirectExist: true }) {
            files {
              id
              name
              metaData
              downloadUrl
              type
              projectId
              isArchived
              sharedWithPortal
              privilege
              stage {
                id
                name
              }
              form {
                id
              }
              createdByContact {
                id
                name
              }
              createdByUser {
                id
                firstName
                lastName
                avatarUrl
              }
              createdAt
              updatedAt
            }
          }
        }
      }
    `,
    { projectId }
  );

  const propertyFiles = projectPropertiesValues.flatMap((value) => value?.files ?? []);
  const combined = [...docs, ...propertyFiles.filter((file) => !docs.some((f) => f.id === file.id))];

  combined.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());

  return combined;
};

interface Props {
  isEnabled?: boolean;
  queryKey: ReactQueryKey;
  projectId: number;
}

export const useDocs = ({ queryKey, isEnabled = true, projectId }: Props) => {
  const {
    data = [],
    isLoading,
    isFetching
  } = useQuery<File[]>([ReactQueryKey.Files, queryKey, projectId], () => fetchDocs({ projectId }), {
    enabled: isEnabled
  });

  return {
    docs: data,
    isLoading,
    isFetching
  };
};
