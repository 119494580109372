import { useMutation, useQuery, useQueryClient } from 'react-query';
import { castArray } from 'lodash';
import { CreateReminderDTO, UpdateReminderDTO } from '@types';
import projectApi from '@services/api/projectApi';
import { useToast } from '@hooks/useToast';
import { apiErrorHandler, trimWithDots } from '@utils';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { Reminder } from '@generated/types/graphql';

export enum ReminderFetchOnly {
  Appointments = 'appointments',
  Reminders = 'reminders',
  All = 'all'
}

const getKey = (companyId: number | number[], recordId?: number, fetchOnly = ReminderFetchOnly.Reminders) => [
  ReactQueryKey.RecordReminders,
  castArray(companyId).sort().join('-'),
  recordId || 'allRecordIds',
  fetchOnly,
  'useReminders'
];

export const useReminders = (
  companyId: number | number[],
  recordId?: number,
  fetchOnly = ReminderFetchOnly.Reminders
) => {
  const companyIds = castArray(companyId);

  const recordIdFilter = recordId
    ? `
    projectId: { equalTo: ${recordId} }
  `
    : '';

  const appointmentsFilter =
    fetchOnly !== ReminderFetchOnly.All
      ? `isAppointment: { equalTo: ${fetchOnly === ReminderFetchOnly.Appointments ? 'true' : 'false'} }`
      : '';

  return useQuery<Reminder[]>(getKey(companyIds, recordId, fetchOnly), async () => {
    try {
      return (
        await postGraphql<any>(
          gql`
          query($companyIds: [Int!]) {
              reminders: reminders(filter: {
                  companyId: { in: $companyIds },
                  ${recordIdFilter},
                  ${appointmentsFilter}
              }, orderBy: [IS_COMPLETED_ASC, DUE_DATE_ASC]) {
                  id
                  title
                  dueDate
                  isCompleted
                  companyId
                  createdAt
                  type
                  description
                  privilege
                  isAppointment
                  project {
                      id
                      type
                      companyId
                      title
                      status
                      requestStatus
                      accountStatus
                  }
                  createdByUser {
                      id
                      firstName
                      lastName
                      avatarUrl
                  }
                  assignee {
                      id
                      firstName
                      lastName
                      avatarUrl
                  }
              }
          }
          `,
          { companyIds }
        )
      ).reminders;
    } catch (e) {
      throw apiErrorHandler('Error fetching reminders', e);
    }
  });
};

export const useRemindersMutations = (companyId?: number, isAppointment?: boolean) => {
  const { showSuccess, showError } = useToast();
  const queryClient = useQueryClient();

  const entityName = isAppointment ? 'Appointment' : 'Reminder';

  const createMutation = useMutation<Reminder, Error, { dto: CreateReminderDTO; recordId: number; companyId?: number }>(
    async ({ dto, recordId, companyId: $companyId }) => {
      try {
        return (
          await projectApi.createReminder(
            recordId,
            { ...dto, isAppointment: isAppointment ?? false },
            companyId || ($companyId as number)
          )
        ).data;
      } catch (e) {
        showError(`Error on ${entityName} creating`);

        throw apiErrorHandler(`Error creating ${entityName}`, e);
      }
    },
    {
      onSuccess: (data, { recordId }) => {
        queryClient.invalidateQueries(ReactQueryKey.RecordReminders);
        queryClient.invalidateQueries(ReactQueryKey.ListViewReminders);

        if (isAppointment) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }
        showSuccess(`${entityName} <b>${trimWithDots(data.title as string, 100)}</b> successfully created`);
      }
    }
  );

  const updateMutation = useMutation<Reminder, Error, { id: number; dto: UpdateReminderDTO; companyId?: number }>(
    async ({ id, dto, companyId: $companyId }) => {
      try {
        return (await projectApi.updateReminder(id, dto, companyId || ($companyId as number))).data;
      } catch (e) {
        showError(`Error on ${entityName} updating`);

        throw apiErrorHandler(`Error updating ${entityName}`, e);
      }
    },
    {
      onSuccess: ({ title, project, isAppointment }) => {
        queryClient.invalidateQueries(ReactQueryKey.RecordReminders);
        queryClient.invalidateQueries(ReactQueryKey.ListViewReminders);

        if (isAppointment) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, project?.id]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }
        showSuccess(
          `${isAppointment ? 'Appointment' : 'Reminder'} <b>${trimWithDots(title as string, 100)}</b> successfully updated`
        );
      }
    }
  );

  const deleteMutation = useMutation<void, Error, { id: number; recordId: number }>(
    async ({ id }) => {
      try {
        await projectApi.deleteReminder(id, companyId);
      } catch (e) {
        throw apiErrorHandler(`Error deleting ${entityName}`, e);
      }
    },
    {
      onSuccess: (_, { recordId }) => {
        queryClient.invalidateQueries(ReactQueryKey.RecordReminders);
        queryClient.invalidateQueries(ReactQueryKey.ListViewReminders);

        if (isAppointment && recordId) {
          queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsListInitialGroupData]);
          queryClient.invalidateQueries([ReactQueryKey.RequestsByIds]);
        }

        showSuccess(`${entityName} successfully deleted`);
      }
    }
  );

  return {
    createMutation,
    updateMutation,
    deleteMutation
  };
};
