import { ReactQueryKey } from '@enums';
import {
  PrivilegedTask,
  PrivilegedTaskFilter,
  PrivilegedTasksConnection,
  PrivilegedTasksOrderBy
} from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { parseUtcDate } from '@utils/dates';
import { gql } from 'graphql-request';
import { useCallback, useMemo, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { DeepPartial } from 'redux';

const PER_PAGE = 20;

export const fetchTasks = async ({
  pageParam,
  filters,
  companyId,
  perPage = PER_PAGE,
  orderBy = PrivilegedTasksOrderBy.IdDesc
}: {
  companyId: number;
  pageParam: number;
  filters: DeepPartial<PrivilegedTaskFilter>;
  orderBy?: PrivilegedTasksOrderBy | PrivilegedTasksOrderBy[];
  perPage?: number;
}) => {
  const resultFilter: DeepPartial<PrivilegedTaskFilter> = {
    isArchived: { equalTo: false },
    // isCompleted: { equalTo: false },
    companyId: { equalTo: companyId },
    isTemplate: { equalTo: false },
    projectExists: true,
    ...filters
  };

  const {
    tasks: { nodes: tasks, totalCount }
  } = await postGraphql<{ tasks: PrivilegedTasksConnection; totalCount: number }>(
    gql`
      query ($filter: PrivilegedTaskFilter, $offset: Int, $first: Int, $orderBy: [PrivilegedTasksOrderBy!]) {
        tasks: privilegedTasksConnection(filter: $filter, offset: $offset, first: $first, orderBy: $orderBy) {
          nodes {
            id
            title
            createdAt
            endDate
            endDateAllDay
            startDate
            startDateAllDay
            priority
            isArchived
            isColored
            isCompleted
            isField
            templateTaskId
            uid
            taskStatus {
              id
              label
            }
            firstLabel {
              color
            }
            privilegedTaskLabels {
              label {
                id
              }
            }
            project {
              id
              title
              type
              geoLocation
              city
              state
              address
              street
              zipcode
              status
              requestStatus
              accountStatus
              stage {
                type
                name
              }
              projectContacts(first: 1) {
                id
                phones
              }
            }
            assignee {
              id
              avatarUrl
              firstName
              lastName
              email
            }
            assigneesByTaskId {
              user {
                avatarUrl
                firstName
                lastName
                id
              }
            }

            taskVisitsByTaskIdConnection(orderBy: START_DATE_ASC) {
              nodes {
                id
                startDate
                startDateAllDay
                endDate
                endDateAllDay
                isCompleted
              }
            }

            privilege

            taskStatusUpdatesByTaskId(orderBy: CREATED_AT_ASC) {
              id
              createdAt
              status
            }

            projectStage {
              id
              name
              type
            }
          }
          totalCount
        }
      }
    `,
    { filter: resultFilter, offset: pageParam * perPage, first: perPage, orderBy }
  );

  return { tasks, totalCount, pageParam };
};

interface Props {
  isEnabled?: boolean;
  filters: DeepPartial<PrivilegedTaskFilter>;
  orderBy?: PrivilegedTasksOrderBy | PrivilegedTasksOrderBy[];
  perPage?: number;
  queryKey: ReactQueryKey;
  isCacheDisabled?: boolean;
}

export type PaginatedTasks = {
  tasks: PrivilegedTask[];
  totalCount: number;
  pageParam: number;
};

export const usePaginatedTasks = ({
  queryKey,
  isEnabled = true,
  filters,
  perPage = PER_PAGE,
  orderBy,
  isCacheDisabled
}: Props) => {
  const loadingRef = useRef<boolean>(false);
  const companyId = useAppSelector(selectWorkspaceId);

  const { data, fetchNextPage, isLoading, isFetching, isFetchingNextPage, refetch } = useInfiniteQuery<PaginatedTasks>(
    [ReactQueryKey.Tasks, queryKey, companyId, filters, orderBy],
    ({ pageParam = 0 }) => fetchTasks({ companyId, pageParam, filters, perPage, orderBy }),
    {
      enabled: isEnabled,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage.tasks.length === perPage ? lastPage.pageParam + 1 : undefined;
      },
      ...(isCacheDisabled ? { cacheTime: 0, staleTime: 0 } : {})
    }
  );

  const handleFetchNextPage = useCallback(() => {
    if (!loadingRef.current) {
      loadingRef.current = true;
      fetchNextPage().finally(() => {
        loadingRef.current = false;
      });
    }
  }, [fetchNextPage]);

  const flatten = useMemo(() => {
    if (!data || !isEnabled) {
      return [];
    }

    return data.pages
      .map((page) => page.tasks)
      .flat()
      .map((task) => ({
        ...task,
        startDate: task.startDate ? parseUtcDate(task.startDate) : null,
        endDate: task.endDate ? parseUtcDate(task.endDate) : null
      }));
  }, [data, isEnabled]);

  return {
    tasks: flatten,
    total: data?.pages[0]?.totalCount ?? 0,
    fetchNextPage: handleFetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    refetch
  };
};
