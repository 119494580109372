import { useToast } from '@hooks/useToast';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadCompressedFile } from '@services/UploadServices/uploadService';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { File as GqlFile } from '@generated/types/graphql';

const INVALID_FILE_EXTENSIONS = ['.bat', '.exe', '.app', '.js', '.ts', '.py'];

const uploadCompanyFile = async (file: File, companyId: number) => {
  const formData = new FormData();
  formData.append('file', file);

  return (await uploadCompressedFile({ formData, by: 'company', entityId: companyId })).data;
};

export const useDropFiles = (onUploaded: (file: GqlFile) => void) => {
  const { showError } = useToast();

  const [uploadingFiles, setUploadingFiles] = useState<File[]>([]);

  const companyId = useAppSelector(selectWorkspaceId);

  const uploadFiles = useCallback(
    async (files: File[]) => {
      const processFile = async (file: File) => {
        const result = await uploadCompanyFile(file, companyId);

        setUploadingFiles((prev) => prev.filter((f) => f !== file));

        onUploaded(result);
      };

      files.forEach((file) => {
        processFile(file);
      });
    },
    [companyId, onUploaded]
  );

  const onDropFiles = useCallback(
    async (acceptedFiles) => {
      const filteredFiles = acceptedFiles.filter((file) => {
        return !INVALID_FILE_EXTENSIONS.some((ext) => file.name.endsWith(ext));
      });

      if (filteredFiles.length === 0) {
        return;
      }

      if (acceptedFiles.some((file) => file.size / 1024 / 1024 > 25)) {
        showError('Maximum file size is 25 MB');

        return;
      }

      setUploadingFiles((prev) => [...prev, ...filteredFiles]);
      uploadFiles(filteredFiles);
    },
    [showError, uploadFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog
  } = useDropzone({
    onDrop: onDropFiles,
    noClick: true,
    accept: {
      'image/*': [],
      'image/heic': [], // Explicitly allow HEIC files
      'video/*': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/pdf': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'text/plain': []
    }
  });

  return {
    uploadingFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    openFileDialog
  };
};
