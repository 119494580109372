import { ReactQueryKey } from '@enums';
import {
  PrivilegedTask,
  PrivilegedTaskFilter,
  PrivilegedTasksConnection,
  PrivilegedTasksOrderBy
} from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { gql } from 'graphql-request';
import { useCallback, useMemo, useRef } from 'react';
import { useInfiniteQuery } from 'react-query';
import { DeepPartial } from 'redux';

const PER_PAGE = 20;

const fetchTasks = async ({
  pageParam,
  filters,
  companyId,
  perPage = PER_PAGE,
  orderBy = PrivilegedTasksOrderBy.IdDesc
}: {
  companyId: number;
  pageParam: number;
  filters: DeepPartial<PrivilegedTaskFilter>;
  orderBy?: PrivilegedTasksOrderBy | PrivilegedTasksOrderBy[];
  perPage?: number;
}) => {
  const resultFilter: DeepPartial<PrivilegedTaskFilter> = {
    isArchived: { equalTo: false },
    // isCompleted: { equalTo: false },
    companyId: { equalTo: companyId },
    isTemplate: { equalTo: false },
    ...filters
  };

  const {
    tasks: { nodes: tasks, totalCount }
  } = await postGraphql<{ tasks: PrivilegedTasksConnection; totalCount: number }>(
    gql`
      query ($filter: PrivilegedTaskFilter, $offset: Int, $first: Int, $orderBy: [PrivilegedTasksOrderBy!]) {
        tasks: privilegedTasksConnection(filter: $filter, offset: $offset, first: $first, orderBy: $orderBy) {
          totalCount
          nodes {
            id
            uid
            title
            isArchived
            isCompleted
            isField
            taskStatus {
              id
              label
            }
            privilegeAll
            privilegeOwn
            privilegeTeam
            endDate
            endDateAllDay

            assignee {
              id
              firstName
              lastName
              avatarUrl
            }

            taskVisitsByTaskIdConnection(filter: { isCompleted: { equalTo: false } }, orderBy: START_DATE_ASC) {
              nodes {
                id
                startDate
                startDateAllDay
              }
            }
          }
        }
      }
    `,
    { filter: resultFilter, offset: pageParam * perPage, first: perPage, orderBy }
  );

  return { tasks, totalCount, pageParam };
};

interface Props {
  isEnabled?: boolean;
  filters: DeepPartial<PrivilegedTaskFilter>;
  orderBy?: PrivilegedTasksOrderBy | PrivilegedTasksOrderBy[];
  perPage?: number;
  queryKey: ReactQueryKey;
}

export type PaginatedTasks = {
  tasks: PrivilegedTask[];
  totalCount: number;
  pageParam: number;
};

export const useTasks = ({ queryKey, isEnabled = true, filters, perPage = PER_PAGE, orderBy }: Props) => {
  const loadingRef = useRef<boolean>(false);
  const companyId = useAppSelector(selectWorkspaceId);

  const { data, fetchNextPage, isLoading, isFetching, isFetchingNextPage } = useInfiniteQuery<PaginatedTasks>(
    [ReactQueryKey.Tasks, queryKey, companyId, filters, orderBy],
    ({ pageParam = 0 }) => fetchTasks({ companyId, pageParam, filters, perPage, orderBy }),
    {
      enabled: isEnabled,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => {
        return lastPage.tasks.length === perPage ? lastPage.pageParam + 1 : undefined;
      }
    }
  );

  const handleFetchNextPage = useCallback(() => {
    if (!loadingRef.current) {
      loadingRef.current = true;
      fetchNextPage().finally(() => {
        loadingRef.current = false;
      });
    }
  }, [fetchNextPage]);

  const flatten = useMemo(() => {
    if (!data || !isEnabled) {
      return [];
    }

    return data.pages.map((page) => page.tasks).flat();
  }, [data, isEnabled]);

  return {
    tasks: flatten,
    fetchNextPage: handleFetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    totalCount: data?.pages[0]?.totalCount ?? 0
  };
};
