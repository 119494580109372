import { ReactQueryKey } from '@enums';
import {
  PrivilegedTask,
  PrivilegedTaskFilter,
  PrivilegedTasksConnection,
  PrivilegedTasksOrderBy
} from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { DeepPartial } from 'redux';

export const fetchTasks = async ({
  companyId,
  filters,
  orderBy = PrivilegedTasksOrderBy.IdDesc
}: {
  companyId: number;
  filters: DeepPartial<PrivilegedTaskFilter>;
  orderBy?: PrivilegedTasksOrderBy | PrivilegedTasksOrderBy[];
}) => {
  const resultFilter: DeepPartial<PrivilegedTaskFilter> = {
    isArchived: { equalTo: false },
    // isCompleted: { equalTo: false },
    companyId: { equalTo: companyId },
    isTemplate: { equalTo: false },
    projectExists: true,
    ...filters
  };

  const {
    tasks: { nodes: tasks }
  } = await postGraphql<{ tasks: PrivilegedTasksConnection }>(
    gql`
      query ($filter: PrivilegedTaskFilter, $orderBy: [PrivilegedTasksOrderBy!]) {
        tasks: privilegedTasksConnection(filter: $filter, orderBy: $orderBy) {
          nodes {
            id
            templateTask {
              id
              title
            }
            uid
            address
            title
            endDate
            endDateAllDay
            startDate
            startDateAllDay
            isCompleted
            isField
            taskStatus {
              id
              label
            }
            assignee {
              id
              avatarUrl
              firstName
              lastName
            }
            taskVisitsByTaskIdConnection(orderBy: START_DATE_ASC) {
              nodes {
                id
                startDate
                startDateAllDay
                endDate
                endDateAllDay
                isCompleted
              }
            }

            privilege
          }
          totalCount
        }
      }
    `,
    { filter: resultFilter, orderBy }
  );

  return tasks;
};

interface Props {
  queryKey: ReactQueryKey;
  filters?: DeepPartial<PrivilegedTaskFilter>;
  isEnabled?: boolean;
  orderBy?: PrivilegedTasksOrderBy | PrivilegedTasksOrderBy[];
}

export const useWorkOrderList = ({ queryKey, filters, isEnabled = true, orderBy }: Props) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<PrivilegedTask[]>(
    [ReactQueryKey.Tasks, queryKey, companyId, filters, orderBy],
    () => fetchTasks({ companyId, filters, orderBy }),
    {
      enabled: isEnabled
    }
  );
};
