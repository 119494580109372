import { useRecordDetail } from '@hooks/useRecordDetail';
import React, { ChangeEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { Archive, Eye, Trash2 } from 'react-feather';
import { useRecordsMutations } from '@hooks/useRecords';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled, selectCompanySubdomain, selectWorkspaceId } from '@state/selectors';
import { CreateProjectDTO, RecordType } from '@types';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { AccountTypeBadge } from '@features/ClientPortfolio/components/Type';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { colors } from '@styles';
import { hasEntityAccess } from '@utils/roles';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useContactMutations } from '@hooks/useContacts';
import { useToast } from '@hooks/useToast';
import { PortalIcon } from '@kit/ui/icons/Portal';
import { navigate } from 'gatsby';
import { XIcon } from '@kit/ui/icons/X';
import { MoreVerticalIcon } from '@kit/ui/icons/MoreVertical';
import { UnarchiveIcon } from '@kit/ui/icons/Unarchive';
import { AutomationChip, EntityType } from '@features/Platform/Automations/Chip';
import {
  Container,
  FirstRow,
  CardInfoBlockHeader,
  Info,
  InfoTitle,
  TitleAndStatus,
  EditableWrapper,
  NameInput,
  ProjectAccountInfoChip,
  PreTitle
} from './styled';
import { Status } from '../../components/Status';
import { HeaderSkeleton } from './Skeleton';
import { EditableField } from '../Overview/Properties/EditableField';
import { InviteToPortal } from '../InviteToPortal';
import { config } from '../../../../config';

interface Props {
  recordId: number;
}

export const Header = ({ recordId }: Props) => {
  const { data: record } = useRecordDetail(recordId);
  const {
    createContactPreviewToken: { mutateAsync: createPreviewToken }
  } = useContactMutations();

  const { showError } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);
  const subdomainName = useAppSelector(selectCompanySubdomain);

  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);
  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();

  const isFetching = !record?.id;
  const {
    update: { mutateAsync: update },
    delete: { mutateAsync: deleteRecord }
  } = useRecordsMutations(companyId, record?.type as RecordType);
  const queryClient = useQueryClient();

  const {
    update: { mutateAsync: updateContact }
  } = useContactMutations(companyId);

  const [title, setTitle] = useState(record?.title);
  const [isTitleEditMode, setIsTitleEditMode] = useState(false);

  useEffect(() => {
    if (!record) {
      return;
    }

    setTitle((prev) => {
      if (!prev) {
        return record.title;
      }

      if (prev !== record.title) {
        return record.title;
      }

      return prev;
    });
  }, [record]);

  const handleTitleClick = useCallback(() => {
    setIsTitleEditMode(true);
  }, []);

  const handleTitleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setTitle(e.target.value);
  }, []);

  const handleTitleBlur = useCallback(() => {
    if (title) {
      setIsTitleEditMode(false);

      update(
        {
          id: record.id,
          dto: { title } as unknown as CreateProjectDTO
        },
        {
          onSuccess: () => {
            queryClient.setQueryData([ReactQueryKey.RecordDetail, record.id], (oldData: any) => {
              if (!oldData) {
                return oldData;
              }

              return {
                ...oldData,
                title
              };
            });
          }
        }
      );
    }
  }, [title, update, record?.id, queryClient]);

  const isAnyContactInvitedToPortal = useMemo(() => {
    if (!record?.projectContacts?.length) {
      return false;
    }

    return record.projectContacts.some((contact) => contact.portalStatus === 'INVITED');
  }, [record]);

  const isAllContactsInvitedToPortal = useMemo(() => {
    if (!record?.projectContacts?.length) {
      return false;
    }

    return record.projectContacts.every((contact) => contact.portalStatus === 'INVITED');
  }, [record]);

  const handleClickInviteToPortal = useCallback(() => {
    openModal<void>(({ onClose }) => <InviteToPortal recordId={recordId} onClose={onClose} />, {
      title: 'Invite Client to Portal'
    });
  }, [openModal, recordId]);

  const handleClickRevokeAccessToPortal = useCallback(async () => {
    if (await confirmDelete('Are you sure you want to revoke access to the Portal for Client?', 'Revoke')) {
      const invitedContacts = record.projectContacts.filter((contact) => contact.portalStatus === 'INVITED');

      await Promise.all(
        invitedContacts.map((contact) => updateContact({ id: contact.id, dto: { portalEnabled: false } }))
      );

      queryClient.invalidateQueries([ReactQueryKey.RecordDetail, recordId]);
    }
  }, [confirmDelete, record?.projectContacts, updateContact, queryClient, recordId]);

  const menuItems = useMemo<MenuItem[]>(
    () =>
      [
        {
          title: 'Invite Client to Portal',
          icon: <PortalIcon size="16px" color="#FFBD13" />,
          isAllowed: isPortalEnabled && isAnyContactInvitedToPortal && !isAllContactsInvitedToPortal,
          onClick: handleClickInviteToPortal
        },
        {
          title: 'Revoke access to Portal',
          icon: <XIcon size="16px" color="#D54855" />,
          isAllowed: isPortalEnabled && isAnyContactInvitedToPortal,
          onClick: handleClickRevokeAccessToPortal
        },
        {
          title: 'Preview Portal as Client',
          icon: <Eye size="16px" color="#9C9CAA" />,
          isAllowed: isPortalEnabled,
          onClick: async () => {
            if (!record.projectContacts?.length) {
              showError('Client has no contacts');

              return;
            }

            const invitedContact = record.projectContacts.find((contact) => contact.portalStatus === 'INVITED');

            const token = await createPreviewToken(invitedContact?.id ?? record.projectContacts[0]?.id);

            window.open(`${config.portalUrl}/${subdomainName}/auth/login-as-company?token=${token}`, '_blank');
          }
        },
        {
          title: 'Archive',
          icon: <Archive size="16px" color="#9C9CAA" />,
          isAllowed: record && record.isActive && hasEntityAccess(record, 'edit'),
          onClick: async () => {
            if (await confirmDelete('Are you sure you want to archive this Client?', 'Archive')) {
              await update({
                id: record.id,
                dto: { isActive: false } as unknown as CreateProjectDTO
              });
            }
          }
        },
        {
          title: 'Unarchive',
          icon: <UnarchiveIcon size="16px" color={colors.green} />,
          isAllowed: record && !record.isActive && hasEntityAccess(record, 'edit'),
          onClick: async () => {
            await update({
              id: record.id,
              dto: { isActive: true } as unknown as CreateProjectDTO
            });
          }
        },
        {
          title: 'Delete forever',
          icon: <Trash2 size="16px" color={colors.red} />,
          isAllowed: record && !record.isActive && hasEntityAccess(record, 'delete'),

          onClick: async () => {
            if (await confirmDelete('Are you sure you want to delete this Client?')) {
              await deleteRecord(
                {
                  id: record.id
                },
                {
                  onSuccess: () => {
                    navigate(`/${companyId}/clients`);
                  }
                }
              );
            }
          }
        }
      ].filter((item) => item.isAllowed),
    [
      record,
      isPortalEnabled,
      update,
      deleteRecord,
      confirmDelete,
      companyId,
      handleClickInviteToPortal,
      createPreviewToken,
      subdomainName,
      isAnyContactInvitedToPortal,
      isAllContactsInvitedToPortal,
      showError,
      handleClickRevokeAccessToPortal
    ]
  );

  if (isFetching) {
    return <HeaderSkeleton />;
  }

  return (
    <Container>
      <Info>
        <FirstRow>
          <TitleAndStatus>
            <Status status={record.accountStatus} />
            {!record.isActive && <Archive color="#9C9CAA" size="16px" />}
            <PreTitle>Client #{record.uid}:</PreTitle>

            {!isTitleEditMode && (
              <EditableWrapper onClick={handleTitleClick}>
                <InfoTitle>{title}</InfoTitle>
              </EditableWrapper>
            )}

            {isTitleEditMode && (
              <NameInput autoFocus value={title} onChange={handleTitleInputChange} onBlur={handleTitleBlur} />
            )}
          </TitleAndStatus>

          <EditableField project={record} propertyName="accountType">
            <ProjectAccountInfoChip isEditable>
              <AccountTypeBadge color="#1D1D35" type={record.accountType} />
            </ProjectAccountInfoChip>
          </EditableField>

          <AutomationChip entityId={record.id} entityType={EntityType.Client} />

          <CardInfoBlockHeader>
            {isPortalEnabled && !isAnyContactInvitedToPortal && (
              <Button variant={ButtonVariant.Primary} onClick={handleClickInviteToPortal}>
                <PortalIcon size="16px" />
                Invite to Portal
              </Button>
            )}
            {menuItems.length > 0 && (
              <ContextMenu items={menuItems}>
                <MoreVerticalIcon size="24px" />
              </ContextMenu>
            )}
          </CardInfoBlockHeader>
        </FirstRow>
      </Info>
    </Container>
  );
};
